import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
// import Button from "@mui/material/Button";
import Button from '../elements/Button';
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
// @material-ui/icons
// import Menu from "@material-ui/icons/Menu";
import MenuIcon from '@mui/icons-material/Menu';
// core components
import styles from "./../../assets_2/jss/material-kit-react/components/headerStyle.js";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
        document.getElementById("the_logo").setAttribute("height", "42px")
        document.getElementById("the_logo").setAttribute("width", "200")
        // document.getElementById("header_buttons_tmp1").classList.add("button-scrolled-header");
        // document.getElementById("header_buttons_tmp2").classList.add("button-scrolled-header");
        // document.getElementById("header_buttons_tmp3").classList.add("button-scrolled-header");
        // document.getElementById("header_buttons_tmp4").classList.add("button-scrolled-header");
        // document.getElementById("header_side_button").classList.add("button-scrolled-header");

      } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
      document.getElementById("the_logo").setAttribute("height", "63px")
      document.getElementById("the_logo").setAttribute("width", "300px")
      // document.getElementById("header_buttons_tmp1").classList.remove("button-scrolled-header");
      // document.getElementById("header_buttons_tmp2").classList.remove("button-scrolled-header");
      // document.getElementById("header_buttons_tmp3").classList.remove("button-scrolled-header");
      // document.getElementById("header_buttons_tmp4").classList.remove("button-scrolled-header");
      // document.getElementById("header_side_button").classList.remove("button-scrolled-header");
    }
  };
  const { 
    color, 
    // rightLinks, 
    leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const brandComponent = brand;
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        {/* {leftLinks !== undefined ? brandComponent : null} */}
        <div className={classes.flex}>
          {/* {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )} */}
          {brandComponent}
        </div>
        <Hidden mdDown implementation="css">
          <Button wideMobile id="header_buttons_tmp1" color="scrolled-header"
            onClick={() => {
              document.getElementById("Information_and_Technologies").scrollIntoView({block: "center", behavior: "smooth"})
            }}
          >
            Services
          </Button>
          <Button wideMobile id="header_buttons_tmp2"color="scrolled-header"
            onClick={() => {
              document.getElementById("Our_Techstach").scrollIntoView({block: "center", behavior: "smooth"})
            }}
          >
            Technologies
          </Button>
          <Button wideMobile id="header_buttons_tmp3"color="scrolled-header"
            onClick={() => {
              document.getElementById("Our_Team").scrollIntoView({block: "center", behavior: "smooth"})
            }}
          >
            The Team
          </Button>
          <Button wideMobile id="header_buttons_tmp4" color="scrolled-header"
            onClick={() => {
              document.getElementById("Contact_Us").scrollIntoView({block: "center", behavior: "smooth"})
            }}
          >
            Contact Us
          </Button>
        </Hidden>
        <Hidden mdUp style={{color: "white"}}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon className="button-scrolled-header"/>
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="inherit"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={()=> setMobileOpen(false)}
        >
          <div className={classes.appResponsive}>
            <Button color="header" wide
              onClick={async () => {
                await setMobileOpen(false);
                await document.getElementById("Information_and_Technologies").scrollIntoView({block: "center", behavior: "smooth"});
              }}
            >
              Services
            </Button>
            <Button color="header" wide
              onClick={async () => {
                await setMobileOpen(false);
                await document.getElementById("Our_Techstach").scrollIntoView({block: "center", behavior: "smooth"});
              }}
            >
              Technologies
            </Button>
            <Button color="header" wide
              onClick={async () => {
                await setMobileOpen(false);
                await document.getElementById("Our_Team").scrollIntoView({block: "center", behavior: "smooth"});
              }}
            >
              The Team
            </Button>
            <Button color="header" wide
              onClick={async () => {
                await setMobileOpen(false);
                await document.getElementById("Contact_Us").scrollIntoView({block: "center", behavior: "smooth"});
              }}
            >
              Contact Us
            </Button>
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "primary",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  // rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
      "defaultColor"
    ]).isRequired,
  }),
};
