import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
// import Logo_tmp from '../components/layout/partials/Logo';
import { ReactComponent as Logo_tmp } from "./../assets/images/logo.svg";
import HeaderLinks from "../components/layout/HeaderLinks.js";

const LayoutDefault = ({ children }) => (
  <>
    {/* <Header navPosition="right" className="reveal-from-bottom" /> */}


    <Header
        id="the_header"
        color="transparent"
        // routes={dashboardRoutes}
        // style={{transition: "0.2s"}}
        brand={<Logo_tmp
          id="the_logo"
          width={300}
          height={63} 
          style={{transition: "0.7s"}}/>}
        // rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "defaultColor",
        }}
        // {...rest}
      />

    {/* <div className="buffer_header_zone">

    </div> */}


    <main className="site-content">
      {children}
    </main>
    <Footer className="the_footer"/>
  </>
);

export default LayoutDefault;  