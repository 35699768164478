import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
// import Input from '../elements/Input';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Button from '../elements/Button';
import ButtonGroup from '../elements/ButtonGroup';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");


  async function sendEmail() {
    const all_the_mails = [
      "chris@thetensortech.com",
      "marios@thetensortech.com",
      "andreas@thetensortech.com",
    ];

    // let the_to_email = "chris@thetensortech.com";
    // const the_to_email = "chris@thetensortech.com";
    // const the_subject = "Contact Us form Email";
    let the_to_email = ""
    const the_telephone = ""

    const the_name = Name;
    const the_from_email = Email;
    const the_message = Message;

    let requestOptions = {}
    let response = "";
    for (let x=0; x < all_the_mails.length; x++){
      the_to_email = all_the_mails[x];
      response = "";
      requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          toemail: the_to_email,
          name: the_name,
          fromemail: the_from_email,
          telephone: the_telephone,
          message: the_message,
        }),
      };

      try {
        response = await fetch(
          "https://email-service.thetensortech.com/api/send-email/",
          requestOptions
        );
      } catch (error) {
        console.log(error);
        alert("Error in sending information");
        return;
      }
      if (response.status !== 200) {
        console.log(response);
        alert("Invalid Input");
        return;
      }
    }
    alert("Email has been sent!");
    clearValues();
  }

  function clearValues() {
    setName("");
    setEmail("");
    setMessage("");
  }


  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );  

  const sectionHeader = {
    title: 'Contact Us',
    paragraph: 'Tell us how our solutions can help your business grow'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <SectionHeader data={sectionHeader} className="center-content" 
            id="Contact_Us"
          />
          <div className="reveal-from-bottom">
              <Grid container spacing={2}>
                <Grid item  xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={Name}
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                </Grid>
                <Grid item  xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={Email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <TextField
                  multiline
                  margin="dense"
                  id="message"
                  label="Message"
                  type="text"
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={Message}
                  required
                  rows={10}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
                </Grid>
                <Grid item xs={12} sm={12} md={2} >
                <ButtonGroup >
                <Button color="primary" wideMobile
                  onClick={() => {
                    if (
                      Name === "" ||
                      Email === "" ||
                      Message === "" 
                      ){
                        alert("Please complete all fields with an asterisk to make the contact form valid!")
                      }
                      else{
                        sendEmail();
                      }
                  }}
                >
                  Send
                </Button>
                </ButtonGroup>
                </Grid>
              </Grid>
              </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;