import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

import Carousel from "react-grid-carousel";

import {
  Pagination,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import { ReactComponent as Printaform } from "./../../assets/images/printaform.svg";
import { ReactComponent as Obsidian } from "./../../assets/images/obslogo.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const OurTeam = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Our Partners",
    // paragraph: 'Meet our team of specialized software developers which will assist you in launching new digital products'
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="reveal-from-bottom">
            <Swiper
              modules={[Pagination, Autoplay]}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              spaceBetween={50}
              slidesPerGroup={3}
              slidesPerView={3}
              speed={1000}
              loop={true}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <Image src="https://static.wixstatic.com/media/c8d208_c18458c43e764381a03e89326bb01cee~mv2.png/v1/fit/w_2500,h_1330,al_c/c8d208_c18458c43e764381a03e89326bb01cee~mv2.png" />
              </SwiperSlide>
              <SwiperSlide>
                <Image src="https://aglantzia.org.cy/aigli/wp-content/uploads/logoAglanjia.png" />
              </SwiperSlide>
              <SwiperSlide>
                <Image src="https://sdg17greece.gr/wp-content/uploads/2020/04/logo-varis.png" />
              </SwiperSlide>
              <SwiperSlide>
                <Image src={require("./../../assets/images/ucy.png")} />
              </SwiperSlide>
              <SwiperSlide>
                <Printaform height={100} />
              </SwiperSlide>
              <SwiperSlide>
                <Obsidian height={100} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

OurTeam.propTypes = propTypes;
OurTeam.defaultProps = defaultProps;

export default OurTeam;
