import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const OurTeam = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Our Team',
    paragraph: 'Meet our team of specialized software developers which will assist you in launching new digital products'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" 
            id="Our_Team"
          />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                <div >
                <Image
                  src={require('./../../assets/images/andreasProxenos.jpeg')}
                  style={{borderRadius: "180px"}}
                  // alt="Features split 02"
                  // width={528}
                  // height={396} 
                  />
              </div>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider invert-color">
                  <span className="testimonial-item-name text-color-high">Andreas Proxenos</span>
                  {/* <span className="text-color-mid"> / </span> */}
                  <span className="testimonial-item-link">
                    CEO
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                <Image
                  src={require('./../../assets/images/christosSeas.jpeg')}
                  style={{borderRadius: "180px"}}
                  />
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider invert-color">
                  <span className="testimonial-item-name text-color-high">Christos Seas</span>
                  {/* <span className="text-color-mid"> / </span> */}
                  <span className="testimonial-item-link">
                    Software Developer
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                <Image
                  src={require('./../../assets/images/mariosKelepeshis.jpeg')}
                  style={{borderRadius: "180px"}}
                  // alt="Features split 02"
                  // width={528}
                  // height={396} 
                  />
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider invert-color">
                  <span className="testimonial-item-name text-color-high">Marios Kelepeshis</span>
                  {/* <span className="text-color-mid"> / </span> */}
                  <span className="testimonial-item-link">
                    Software Developer
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

OurTeam.propTypes = propTypes;
OurTeam.defaultProps = defaultProps;

export default OurTeam;