import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import OurTeam from '../components/sections/OurTeam';
import TestimonialsNew from '../components/sections/TestimonialsNew';
import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>
      <Hero invertColor className="buffer_header_zone"/>
      <FeaturesTiles className="after_wave_section"/>
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <OurTeam topDivider/>
      <TestimonialsNew topDivider/>
      <Cta topDivider />
    </>
  );
}

export default Home;