import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
// import Image from '../elements/Image';

import { ReactComponent as MobileApp } from "./../../assets/images/mobile-app.svg";
import { ReactComponent as SettingsApp } from "./../../assets/images/settings.svg";
import { ReactComponent as WebApp } from "./../../assets/images/web-programming.svg";

import { ReactComponent as CustomApp } from "./../../assets/images/custom-design.svg";
import { ReactComponent as CloudApp } from "./../../assets/images/cloud-computing.svg";
import { ReactComponent as ITConsulting } from "./../../assets/images/IT-consulting.svg";

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Our Services',
    // paragraph: 'We serve companies in all tech related industries with high quality digital services which are presented below.',
    paragraph: 'We run all kinds of services in form of',
    subparagraph: 'Information & Technologies',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" 
          id="Information_and_Technologies"/>
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <MobileApp
                      width={70}
                      height={70} 
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                      Mobile App Development
                    </h4>
                  <p className="m-0 text-sm">
                    Mobile apps provide thrilling and engaging experiences that connect users with brands. Native, hybrid, and cross-platform mobile application development offers new and lasting competitive advantages.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <SettingsApp
                      width={70}
                      height={70} 
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Desktop applications
                  </h4>
                  <p className="m-0 text-sm">
                    We create Desktop Apps for Windows, Linux, and MacOS platforms which will delight you with their rich functionality, speed and user-friendly interface.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <WebApp
                      width={70}
                      height={70} 
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Web Application
                    </h4>
                  <p className="m-0 text-sm">
                  Web application development is one of our core services. Tensor Tech creates web apps to support your project, depending on its particular needs.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <CustomApp
                      width={70}
                      height={70} 
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Custom software development
                    </h4>
                  <p className="m-0 text-sm">
                  We plan tailored software solutions for you to achieve operational excellence and deliver them fast and error-free.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <CloudApp
                      width={70}
                      height={70} 
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Cloud & DevOps Services
                    </h4>
                  <p className="m-0 text-sm">
                  {/* DevOps is a software development methodology that encourages close collaboration between software engineers and IT operations personnel. */}
                  We offer fully automated DevOps environments by bringing development and operation teams together. Combining this with master cloud solutions, makes us able to deliver quality products.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <ITConsulting
                      width={70}
                      height={70} 
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    IT consulting
                  </h4>
                  <p className="m-0 text-sm">
                  We offer IT consulting services that will help you improve your software architecture, create a tech-driven digital strategy, and improve operations by optimising your software portfolio.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;